import { OktaAuth } from '@okta/okta-auth-js';

const oktaAuth = new OktaAuth({
  issuer: process.env.OKTA_ISSUER || 'https://tenantinc.okta.com/',
  clientId: process.env.OKTA_CLIENT_ID || '0oaihbj3hcabyTzGU697',
  redirectUri: window.location.origin + '/callback',
  scopes: ['openid', 'profile', 'email'],
  pkce: true // Enable PKCE for enhanced security
});

export default oktaAuth;
